import React from 'react';
import styled from 'styled-components';
import SocialLinks from './SocialLinks';
import { HashLink } from 'react-router-hash-link';

const Navbar = () => {
    return (
        <NavbarContainer>
            <NavLeft>
                <h2>Oriol Serra</h2>
            </NavLeft>
            <NavMiddle>
                <ul>
                    <li>
                        <Link smooth to="/#about-me">About Me</Link>
                    </li>
                    <li>
                        <Link smooth to="/#my-work">My Work</Link>
                    </li>
                    <li>
                        <Link smooth to="/#contact">Contact</Link>
                    </li>
                </ul>
            </NavMiddle>
            <NavRight>
                <SocialLinks />
            </NavRight>
        </NavbarContainer>
    )
}

export default Navbar;

const NavbarContainer = styled.nav`
    max-width: 100vw;
    color: black;
    padding: 20px;
    display: flex;
    align-items: center;
`;

const NavLeft = styled.div`
    flex: 1;
    text-align: center;

    h2 {
        font-family: "p22-mackinac-pro", serif;
        font-weight: 500;
        font-style: italic;
    }

    @media (min-width: 740px) {
        flex: 0.3;
        text-align: left;
    }
`;

const NavMiddle = styled.div`
    display: none;

    @media (min-width: 740px) {
        display: inline;
        flex: 0.4;

        ul {
            display: flex;
            justify-content: center;
        }

        li {
            margin: 0 12px 0 12px;
            list-style: none;
            transition: opacity 0.5s;

            :hover {
                opacity: 0.5;
            }
        }
    }
`;

export const NavRight = styled.div`
    display: none;

    @media (min-width: 740px) {
        display: inline;
        flex: 0.3;
        font-size: 17px;
    }
`;

const Link = styled(HashLink)`
    color: black;
    text-decoration: none;
`;