import React from 'react';
import styled from 'styled-components';

const Header = () => {
    return (
        <HeaderContainer id="about-me">
            <HeaderLeft>
                <img src="portrait.png" alt='Portrait of me' />
            </HeaderLeft>
            <HeaderRight>
                <h1>I'm Oriol Serra,<br/> a <span>Front-End Developer</span>.</h1>
                <p>I'm currently working with <span>React</span> and <span>React Native</span> as my main libraries to build web app front-ends, along with <span>TypeScript</span> for type checking, <span>LESS</span> for styling, <span>Redux</span> to manage state, and <span>Testing Library</span> for <span>TDD</span>. I use <span>Microfrontends</span> for improved modularity and scalability, and implement <span>i18next</span> for a seamless user experience across different languages.</p>
                <br />
                <p>To streamline the development and deployment process, I work with <span>Jenkins</span> for CI/CD. In addition to these technologies, I prioritize <span>mobile-first design</span> and <span>accessibility</span> in my projects, adhering to best practices and creating inclusive web experiences for all users.</p>
                <br />
                <p>Check out my CV <CVLink href='CV.pdf' download>here</CVLink>.</p>
            </HeaderRight>
        </HeaderContainer>
    )
}

export default Header;

const HeaderContainer = styled.header`
    max-width: 100vw;
    display: flex;
    flex-wrap: wrap;
    margin: 80px 35px 100px;
`;

const HeaderLeft = styled.div`
    display: none;

    @media (min-width: 740px) {
        display: flex;
        flex: 0.4;
        justify-content: flex-end;
        align-items: center;

        img {
            height: 230px;
            width: auto;
            border-radius: 10px;
        }
    }

    @media (min-width: 974px) {
        img {
            height: 380px;
            width: auto;
            border-radius: 10px;
        }
    }

    @media (min-width: 1800px) {
        flex: 0.5;
    }
`;

const HeaderRight = styled.div`
    flex: 1;
    text-align: center;

    h1 {
            margin-bottom: 40px;
            font-size: 40px;
        }

    span {
        font-family: "p22-mackinac-pro", serif;
        font-weight: 500;
        font-style: italic;
    }

    p {
        width: 90%;
        max-width: 600px;
        margin: 0 auto;
    }

    @media (min-width: 740px) {
        flex: 0.6;
        text-align: left;
        margin-left: 50px;
        p {
            margin: unset;
        }
    }
`;

const CVLink = styled.a`
    color: black;
    text-decoration: none;
    cursor: pointer;
    font-family: "p22-mackinac-pro", serif;
    font-weight: 500;
    font-style: italic;
    transition: opacity 0.5s;

    :hover {
        opacity: 0.5;
    }
`