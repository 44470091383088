import React from 'react';
import styled from 'styled-components';
import { SectionContainer } from './Work';

const Contact = () => {
    return (
        <SectionContainer id="contact" style={{ marginBottom: 100 }}>
            <h3>Contact</h3>
            <Email><a href="mailto:hello@devoriolserra.com">hello@devoriolserra.com</a></Email>
        </SectionContainer>
    )
}

export default Contact;

const Email = styled.h4`
    font-size: 20px;
    
    a {
        color: black;
        text-decoration: none;
        transition: opacity 0.5s;

        :hover {
            opacity: 0.5;
        }
    }
`;
