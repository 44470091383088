import React from 'react';
import styled from 'styled-components';
import SocialLinks from './SocialLinks';

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        <FooterContainer>
            <FooterLeft>
                <p>&copy; {currentYear}</p>
            </FooterLeft>
            <FooterRight>
                <SocialLinks />
            </FooterRight>
        </FooterContainer>
    )
}

export default Footer;

const FooterContainer = styled.footer`
    max-width: 100vw;
    margin-bottom: 0;
    padding: 20px;
    display: flex;
`;

const FooterLeft = styled.div`
    flex: 0.5;
    justify-content: flex-start;

    a {
        text-decoration: none;
        color: black;
        transition: opacity 0.5s;

        :hover {
            opacity: 0.5;
        }
    }
`;

const FooterRight = styled.div`
    flex: 0.5;
    text-align: right;
    justify-content: end;
`;