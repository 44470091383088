import React from 'react';
import styled from 'styled-components';

const SocialLinks = () => {
    return (
        <SocialContainer>
            <li><a href='https://twitter.com/byoriolserra' target='_blank' rel="noreferrer"><i class="fab fa-twitter" /><p>Twitter</p></a></li>
            <li><a href='https://www.instagram.com/byoriolserra/' target='_blank' rel="noreferrer"><i class="fab fa-instagram" /><p>Instagram</p></a></li>
            <li><a href='https://github.com/byoriolserra' target='_blank' rel="noreferrer"><i class="fab fa-github" /><p>GitHub</p></a></li>
            <li><a href='https://www.linkedin.com/in/serra-oriol/' target='_blank' rel="noreferrer"><i class="fab fa-linkedin" /><p>LinkedIn</p></a></li>
        </SocialContainer>
    )
}

export default SocialLinks;

const SocialContainer = styled.ul`

    display: flex;
    justify-content: flex-end;

    li {
        list-style: none;
        margin-left: 15px;
    }

    a {
        text-decoration: none;
        color: black;
        transition: opacity 0.5s;

        :hover {
            opacity: 0.5;
        }
    }

    p {
        display: none;
    }
`;
