import React from 'react';
import styled from 'styled-components';

const Project = ({project}) => {
    return (
        <ProjectContainer>
            <ProjectLink href={project.link} target='_blank' rel="noreferrer" >
                <ImageContainer>{project.image? <img src={project.image} alt='' /> : 'Coming Soon!'}</ImageContainer>
                <h4>{project.name}</h4>
                <p>{project.description}</p>
            </ProjectLink>
        </ProjectContainer>
    )
}

export default Project;

const ProjectContainer = styled.div`
    width: 270px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 20px;
    transition: opacity 0.5s;

    h4 {
        font-family: "p22-mackinac-pro", serif;
        font-size: 20px;
        font-weight: 500;
        font-style: italic;
        margin: 10px 0;
    }

    :hover {
        opacity: 0.75;
    }
`;

const ProjectLink = styled.a`
    cursor: pointer;
    text-decoration: none;
    color: black;
`;

const ImageContainer = styled.div`
    width: 270px;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    background-color: lightgrey;
    overflow: hidden;
    filter: grayscale(100%);

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top left;
    }
`;
