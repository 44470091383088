import React from 'react';
import styled from 'styled-components';
import Project from './Project';


const Work = () => {

    const projectsArray = [{
        name: 'ABA English',
        image: 'aba.png',
        description: 'An award-winning online academy recognized by Google and Apple offering audiovisual English courses to over 30 million students worldwide.',
        link: 'https://academy.abaenglish.com'
    }]

    return (
        <SectionContainer id="my-work">
            <h3>My Work</h3>
            <WorkTable>
                {projectsArray.map(project => <Project project={project}/>)}
            </WorkTable>
        </SectionContainer>
    )
}

export default Work;

export const SectionContainer = styled.section`
    margin-bottom: 70px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        margin-bottom: 50px;
        font-family: "p22-mackinac-pro", serif;
        font-size: 27px;
        font-weight: 500;
        font-style: italic;
    }
`;

const WorkTable = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
`;