import React from 'react';
import styled from 'styled-components';

const Skills = () => {
    return (
        <SkillsContainer>
            <ul>
                <li><a href='https://www.typescriptlang.org/' target='_blank' rel="noreferrer"><img src='././typescript.png' alt='TypeScript icon'/><p>TypeScript</p></a></li>
                <li><a href='https://lesscss.org' target='_blank' rel="noreferrer"><i class="fa-brands fa-less" /><p>LESS</p></a></li>
                <li><a href='https://reactjs.org' target='_blank' rel="noreferrer"><i class="fab fa-react" /><p>React</p></a></li>
                <li><a href='https://jestjs.io' target='_blank' rel="noreferrer"><img src='././jest.png' alt='Jest icon'/><p>Jest</p></a></li>
                <li><a href='https://testing-library.com' target='_blank' rel="noreferrer"><img src='././testing-library.png' alt='Testing Library icon'/><p>Testing Library</p></a></li>
                <li><a href='https://redux.js.org' target='_blank' rel="noreferrer"><img src='././redux.png' alt='Redux icon'/><p>Redux</p></a></li>
                <li><a href='https://nextjs.org' target='_blank' rel="noreferrer"><img src='././nextjs.png' alt='Next.js icon'/><p>Next.js</p></a></li>
                <li><a href='https://git-scm.com' target='_blank' rel="noreferrer"><i class="fab fa-git" /><p>Git</p></a></li>
                <li><a href='https://www.jenkins.io/' target='_blank' rel="noreferrer"><i class="fa-brands fa-jenkins" /><p>Jenkins</p></a></li>
                <li><a href='https://www.a11yproject.com' target='_blank' rel="noreferrer"><i class="fas fa-universal-access" /><p>A11y</p></a></li>
            </ul>
        </SkillsContainer>
    )
}

export default Skills;

const SkillsContainer = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;
    font-size: 25px;

    ul {
        display: flex;
        flex-wrap: wrap;
        margin: 0 20px;
        justify-content: center;

    }

    li {
        list-style: none;
        margin: 0 10px 0 10px;
        filter: grayscale(100%);
    }

    img {
        position: relative;
        top: 2px;
        width: 22px;
    }

    a {
        text-decoration: none;
        color: black;
        transition: opacity 0.5s;

        :hover {
            opacity: 0.5;
        }
    }

    p {
        display: none;
    }
`;
